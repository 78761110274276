<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center p-1">
      <i class="fas fa-info-circle" style="color:#efd179;font-size: 15px;position: absolute;right: 7px;top:7px" v-if="description != null" v-b-tooltip v-bind:title="msg(description)"></i>
      <div class="truncate">
        <div class="text-primary font-weight-bold" style="font-size: 17px;margin-bottom: 5px">{{msg(statisticTitle) }}</div>
        <slot name="content" v-if="!loading">
          <h2 style="font-size: 20px!important;" class="mb-25 font-weight-bolder">
            <slot v-if="formatNumber"  name="content-text">
              {{ statistic | numberFormatter}} {{ postFix }}
            </slot>
            <slot v-else  name="content-text">
              {{ statistic }} {{ postFix }}
            </slot>
          </h2>
        </slot>
        <div v-else>
          <b-skeleton></b-skeleton>
        </div>
        <slot name="footer" v-if="!loading">
          <span class="text-muted" style="font-size: 12px">{{msg(statisticDescription) }}</span>
        </slot>

      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon v-if="iconType === 'feather'" size="21" :icon="icon"/>
        <i v-if="iconType === 'fontawesome'" v-bind:class="{[icon]:true}" style="font-size: 21px;" v-bind:style="iconStyle"/>
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    loading:{
      default:()=>false
    },
    icon: {
      type: String,
      required: true,
    },
    iconType:{
      default:()=>"feather",
    },
    iconStyle:{

    },
    statistic: {
      type: [Number, String],
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticDescription:{
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    formatNumber: {
      type: Boolean,
      default: () => false,
    },
    postFix: {
      type: String,
      default: ()=> '',
    },
    description:{

    }
  },
}
</script>
